import "../../styles/global.scss";

import styled, { ThemeProvider } from "styled-components";

import React from "react";
import { edbaLogoWhite } from "../../assets/images/hero";
import theme from "../../utils/Theme";

const HeaderWithLogo = () => {
  return (
    <ThemeProvider theme={theme}>
      <TopSection>
        <LogoWrapper>
          <a href="/">
            <Logo src={edbaLogoWhite} alt="Go to edba.io" />
          </a>
        </LogoWrapper>
      </TopSection>
    </ThemeProvider>
  );
};

const CopyrightFooter = () => {
  return (
    <ThemeProvider theme={theme}>
      <CopyrightWrapper>
        <CopyrightText>
          &copy; Copyright {new Date().getFullYear()} EDBA Software LLP&nbsp;
        </CopyrightText>
      </CopyrightWrapper>
    </ThemeProvider>
  );
};

const Wrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  @media ${({ theme }) => theme.media.small} {
    padding: 0 2rem;
    max-width: 100%;
  }
`;

const TopSection = styled.div`
  background: var(--primary-color);
  height: 10rem;
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled(Wrapper)`
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Logo = styled.img`
  height: 5rem;
`;

const CopyrightWrapper = styled.div`
  width: 100%;
  background: var(--primary-color);
  margin-top: 2rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CopyrightText = styled.p`
  width: 90%;
  color: white;
  @media ${({ theme }) => theme.media.small} {
    text-align: center;
  }
`;

export { HeaderWithLogo, CopyrightFooter };
