import "../styles/global.scss";

import {
  CopyrightFooter,
  HeaderWithLogo,
} from "../components/HeaderFooter/HeaderFooter";
import styled, { ThemeProvider } from "styled-components";

import React from "react";
import theme from "../utils/Theme";

const PrivacyPolicyPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <HeaderWithLogo />
      <Wrapper>
        <Heading>PRIVACY POLICY FOR EDBA SOFTWARE LLP</Heading>
        <Paragraph>
          At EDBA Software LLP, accessible from app.edba.io, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by EDBA Software LLP and how we use it.
        </Paragraph>
        <Paragraph>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </Paragraph>
        <Paragraph>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in EDBA.
        </Paragraph>
        <Subheading>Consent</Subheading>
        <Paragraph>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </Paragraph>
        <Subheading>Information we collect</Subheading>
        <Paragraph>
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </Paragraph>
        <Paragraph>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </Paragraph>
        <Paragraph>
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </Paragraph>
        <Subheading>How we use your information</Subheading>
        <Paragraph>
          We use the information we collect in various ways, including to:
        </Paragraph>
        <ul>
          <Bullet>
            <Paragraph>Provide, operate, and maintain our website</Paragraph>
          </Bullet>
          <Bullet>
            <Paragraph>Improve, personalize, and expand our website</Paragraph>
          </Bullet>
          <Bullet>
            <Paragraph>
              Understand and analyze how you use our website
            </Paragraph>
          </Bullet>
          <Bullet>
            <Paragraph>
              Develop new products, services, features, and functionality
            </Paragraph>
          </Bullet>
          <Bullet>
            <Paragraph>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </Paragraph>
          </Bullet>
          <Bullet>
            <Paragraph>Send you emails</Paragraph>
          </Bullet>
          <Bullet>
            <Paragraph>Find and prevent fraud</Paragraph>
          </Bullet>
        </ul>
        <Subheading>Log Files</Subheading>
        <Paragraph>
          EDBA follows a standard procedure of using log files. These files log
          visitors when they visit websites. All hosting companies do this as
          part of hosting services' analytics. The information collected by log
          files include internet protocol (IP) addresses, browser type, Internet
          Service Provider (ISP), date and time stamp, referring/exit pages, and
          possibly the number of clicks. These are not linked to any information
          that is personally identifiable. The purpose of the information is for
          analyzing trends, administering the site, tracking users' movement on
          the website, and gathering demographic information.
        </Paragraph>
        <Subheading>Cookies and Web Beacons</Subheading>
        <Paragraph>
          Like any other website, EDBA uses 'cookies'. These cookies are used to
          store information including visitors' preferences, and the pages on
          the website that the visitor accessed or visited. The information is
          used to optimize the users' experience by customizing our web page
          content based on visitors' browser type and/or other information.
        </Paragraph>
        <Subheading>Advertising Partners Privacy Policies</Subheading>
        <Paragraph>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of EDBA.
        </Paragraph>
        <Paragraph>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on EDBA, which are sent directly
          to users' browser. They automatically receive your IP address when
          this occurs. These technologies are used to measure the effectiveness
          of their advertising campaigns and/or to personalize the advertising
          content that you see on websites that you visit.
        </Paragraph>
        <Paragraph>
          Note that EDBA has no access to or control over these cookies that are
          used by third-party advertisers.
        </Paragraph>
        <Subheading>Third Party Privacy Policies</Subheading>
        <Paragraph>
          EDBA's Privacy Policy does not apply to other advertisers or websites.
          Thus, we are advising you to consult the respective Privacy Policies
          of these third-party ad servers for more detailed information. It may
          include their practices and instructions about how to opt-out of
          certain options.
        </Paragraph>
        <Paragraph>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </Paragraph>
      </Wrapper>
      <CopyrightFooter />
    </ThemeProvider>
  );
};

const Wrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  @media ${({ theme }) => theme.media.small} {
    padding: 0 2rem;
    max-width: 100%;
  }
`;

const Heading = styled.h1`
  margin-top: 4rem;
  font-size: 4rem;
  @media ${({ theme }) => theme.media.small} {
    font-size: 2.4rem;
  }
`;

const Paragraph = styled.p`
  margin-top: 2rem;
  text-align: justify;
  text-justify: inter-word;
`;

const Subheading = styled.h2`
  font-size: 2.8rem;
  margin-top: 2.4rem;
  @media ${({ theme }) => theme.media.small} {
    font-size: 1.8rem;
    margin-top: 2rem;
  }
`;

const Bullet = styled.li`
  margin-left: 4rem;
  color: rgb(0, 0, 0, 0.4);
  font-size: 2.5rem;
  line-height: 2rem;
  @media ${({ theme }) => theme.media.small} {
    margin-left: 3rem;
    font-size: 2rem;
    line-height: 1.5rem;
  }
`;

export default PrivacyPolicyPage;
